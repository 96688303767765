/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import moment from "moment";
import { isNil } from "lodash";
import { DateTime } from 'luxon';

  // timezone mapping
  export const timezoneMapping = {
    "(GMT-11:00) Midway Island, Samoa": "Pacific/Midway",
    "(GMT-10:00) Hawaii": "Pacific/Honolulu",
    "(GMT-09:00) Alaska": "America/Anchorage",
    "(GMT-08:00) Pacific Time (US and Canada); Tijuana": "America/Los_Angeles",
    "(GMT-07:00) Mountain Time (US and Canada)": "America/Denver",
    "(GMT-06:00) Central Time (US and Canada)": "America/Chicago",
    "(GMT-05:00) Eastern Time (US and Canada)": "America/New_York",
    "(GMT-12:00) Dateline Standard Time": "Etc/GMT+12",
    "(GMT-07:00) Chihuahua, La Paz, Mazatlan": "America/Chihuahua",
    "(GMT-07:00) Arizona": "America/Phoenix",
    "(GMT-06:00) Saskatchewan": "America/Regina",
    "(GMT-06:00) Guadalajara, Mexico City, Monterrey": "America/Mexico_City",
    "(GMT-06:00) Central America": "America/Guatemala",
    "(GMT-05:00) Indiana (East)": "America/Indianapolis",
    "(GMT-05:00) Bogota, Lima, Quito": "America/Bogota",
    "(GMT-04:30) Caracas": "America/Caracas",
    "(GMT-04:00) Atlantic Time (Canada)": "America/Halifax",
    "(GMT-04:00) La Paz": "America/La_Paz",
    "(GMT-04:00) Santiago": "America/Santiago",
    "(GMT-03:30) Newfoundland": "America/St_Johns",
    "(GMT-03:00) Brasilia": "America/Sao_Paulo",
    "(GMT-03:00) Buenos Aires, Georgetown": "America/Argentina/Buenos_Aires",
    "(GMT-03:00) Greenland": "America/Godthab",
    "(GMT-02:00) Mid-Atlantic": "Atlantic/South_Georgia",
    "(GMT-01:00) Azores": "Atlantic/Azores",
    "(GMT-01:00) Cape Verde Islands": "Atlantic/Cape_Verde",
    "(GMT) Greenwich Mean Time": "GMT",
    "(GMT) Casablanca, Monrovia": "Africa/Casablanca",
    "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague": "Europe/Belgrade",
    "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb": "Europe/Warsaw",
    "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris": "Europe/Paris",
    "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna": "Europe/Berlin",
    "(GMT+01:00) West Central Africa": "Africa/Lagos",
    "(GMT+02:00) Athens, Bucharest, Istanbul": "Europe/Athens",
    "(GMT+02:00) Cairo": "Africa/Cairo",
    "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius": "Europe/Helsinki",
    "(GMT+02:00) Minsk": "Europe/Minsk",
    "(GMT+02:00) Jerusalem": "Asia/Jerusalem",
    "(GMT+02:00) Harare, Pretoria": "Africa/Harare",
    "(GMT+03:00) Moscow, St. Petersburg, Volgograd3": "Europe/Moscow",
    "(GMT+03:00) Kuwait, Riyadh": "Asia/Riyadh",
    "(GMT+03:00) Nairobi": "Africa/Nairobi",
    "(GMT+03:00) Baghdad": "Asia/Baghdad",
    "(GMT+03:30) Tehran": "Asia/Tehran",
    "(GMT+04:00) Abu Dhabi, Muscat": "Asia/Dubai",
    "(GMT+04:00) Baku": "Asia/Baku",
    "(GMT+04:30) Kabul": "Asia/Kabul",
    "(GMT+05:00) Ekaterinburg": "Asia/Yekaterinburg",
    "(GMT+05:00) Islamabad, Karachi, Tashkent": "Asia/Karachi",
    "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi": "Asia/Kolkata",
    "(GMT+05:45) Kathmandu": "Asia/Kathmandu",
    "(GMT+06:00) Astana, Dhaka": "Asia/Dhaka",
    "(GMT+06:00) Sri Jayawardenepura": "Asia/Colombo",
    "(GMT+06:00) Almaty, Novosibirsk": "Asia/Almaty",
    "(GMT+06:30) Rangoon": "Asia/Rangoon",
    "(GMT+07:00) Bangkok, Hanoi, Jakarta": "Asia/Bangkok",
    "(GMT+07:00) Krasnoyarsk": "Asia/Krasnoyarsk",
    "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi": "Asia/Shanghai",
    "(GMT+08:00) Kuala Lumpur, Singapore": "Asia/Singapore",
    "(GMT+08:00) Taipei": "Asia/Taipei",
    "(GMT+08:00) Perth": "Australia/Perth",
    "(GMT+08:00) Irkutsk, Ulaan Bataar": "Asia/Irkutsk",
    "(GMT+09:00) Seoul": "Asia/Seoul",
    "(GMT+09:00) Osaka, Sapporo, Tokyo": "Asia/Tokyo",
    "(GMT+09:00) Yakutsk": "Asia/Yakutsk",
    "(GMT+09:30) Darwin": "Australia/Darwin",
    "(GMT+09:30) Adelaide": "Australia/Adelaide",
    "(GMT+10:00) Canberra, Melbourne, Sydney": "Australia/Sydney",
    "(GMT+10:00) Brisbane": "Australia/Brisbane",
    "(GMT+10:00) Hobart": "Australia/Hobart",
    "(GMT+10:00) Vladivostok": "Asia/Vladivostok",
    "(GMT+10:00) Guam, Port Moresby": "Pacific/Guam",
    "(GMT+11:00) Magadan, Solomon Islands, New Caledonia": "Asia/Magadan",
    "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands": "Pacific/Fiji",
    "(GMT+12:00) Auckland, Wellington": "Pacific/Auckland",
    "(GMT+13:00) Nuku'alofa": "Pacific/Tongatapu",
    "(GMT+04:00) Tbilisi": "Asia/Tbilisi",
    "(GMT+04:00) Yerevan": "Asia/Yerevan",
  };

export function isToday(dateStart, dateFormat) {
  let start = moment(dateStart);
  if (dateFormat) {
    start = moment(dateStart, dateFormat);
  }
  const today = moment().startOf("day");
  return start.isSame(today, "d");
}

export function isAfterToday(dateStart) {
  const start = moment(dateStart);
  const today = moment().startOf("day");
  return start.isAfter(today, "d");
}

export function isBeforeToday(dateStart) {
  const start = moment(dateStart);
  const today = moment().startOf("day");
  return start.isBefore(today, "d");
}

export function getTimeZone(timeZones, event) {
  if (event === null || event === undefined) return null;

  const timeZoneId = event.timeZoneId;
  let timeZone = timeZones[timeZoneId];

  return timeZone;
}

/**
 * Return the timezone string to display in the UI.  The timezone
 * string is of the format:
 *
 * 03:00PM,  Eastern Standard Time (GMT-05:00)
 *
 * This is what is in the v1.3 UI and we want to maintain consistency.
 *
 * @param {*} timeZones
 * @param {*} event
 */
export function getTimeZoneById(timeZones, event) {
  if (event === null) return "";

  const timeZoneId = event.timeZoneId;
  if (timeZoneId === undefined) return "";

  let timeZone = timeZones[timeZoneId];
  const timeZoneStr = timeZone.timeZone;
  if (timeZoneStr === null) return "";

  const offset = timeZoneStr.substr(0, timeZoneStr.indexOf(")") + 1);

  return `${timeZone.dotNetId} ${offset}`;
}

/**
 * Convert between timezone offset from REST API to timezone offset
 * that momentjs expects.   We need to pad the timezone offset and
 * make sure that it is a four digit value.
 *
 * @param {*} timeZones
 * @param {*} event
 */
export function getTimeZoneOffset(timeZones, event) {
  if (event === null) return "0000";

  let timeZoneOffset = null;
  let isNegative = false;
  const timeZoneId = event.timeZoneId;
  if (timeZoneId === undefined) return "0000";

  timeZoneOffset = timeZones[timeZoneId].timeZoneGMTDiff;
  if (timeZoneOffset < 0) {
    isNegative = true;
    timeZoneOffset = Math.abs(timeZoneOffset);
  }

  timeZoneOffset *= 100;
  let momentTz = "0000" + timeZoneOffset;
  momentTz = momentTz.substr(momentTz.length - 4, momentTz.length);
  if (isNegative) momentTz = "-" + momentTz;

  return momentTz;
}

export const getStartTimeWithUtcOffset = (timeZones, event) => {
  if (event === null) return;

  const tzOffset = getTimeZoneOffset(timeZones, event);
  const startTime = moment(event.startDate, "YYYY-MM-DDTHH:mmZ").toDate();
  return startTime;
};

export const getStartTimeWithTimeZoneString = (timeZones, event) => {
  if (event === null) return;

  // const tzOffset = getTimeZoneOffset(timeZones, event);

  let timeZoneGMTDiff = !isNil(event.timeZoneId)
    ? timeZones.find((v) => v.timeZoneId === event.timeZoneId).timeZoneGMTDiff
    : "";
  let utcOffset = getTimezoneGMTDiffString(timeZoneGMTDiff);

  const startTime = moment(event.startDate, "YYYY-MM-DDTHH:mmZ")
    .utcOffset(utcOffset)
    .format("hh:mmA");

  const timeZoneString = timeZones.find((v) => v.timeZoneId === event.timeZoneId);

  return `${startTime}, ${timeZoneString.timeZone}`;
};

export function getTimezoneGMTDiffString(timeZoneGMTOffset) {
  let timeZoneGMTDiff = timeZoneGMTOffset * 100;
  let isPos = timeZoneGMTDiff > 0;
  timeZoneGMTDiff = Math.abs(timeZoneGMTDiff);
  timeZoneGMTDiff = (isPos ? "+" : "-") + pad(timeZoneGMTDiff, 4);
  return timeZoneGMTDiff;
}

export function calculateTimeWithDST(dateString, hour, minute, selectedTimeZone) {
  if (!dateString || !hour || !minute || !selectedTimeZone) {
    return null;
  }

  try {
    const timeZoneSelectedId = timezoneMapping[selectedTimeZone];
    
    if (!timeZoneSelectedId) {
      return null;
    }

    const parsedDate = DateTime.fromISO(dateString);
    
    if (!parsedDate.isValid) {
      return null;
    }

    const selectedDateTime = DateTime.fromObject(
      {
        year: parsedDate.year,
        month: parsedDate.month,
        day: parsedDate.day,
        hour: parseInt(hour, 10),
        minute: parseInt(minute, 10)
      },
      {
        zone: timeZoneSelectedId
      }
    );

    return selectedDateTime.isValid 
      ? selectedDateTime.toFormat("yyyy-MM-dd'T'HH:mmZZZ")
      : null;

  } catch (error) {
    return null;
  }
}

export function getTimezoneDSTOffsetString(dateString, timezone) {
  try {

    const timeZoneSelectedId = timezoneMapping[timezone];
    // Create DateTime object in the specified timezone
    const dt = DateTime.fromISO(dateString, { zone: timeZoneSelectedId });
    
    if (!dt.isValid) {
      return null;
    }

    // Get offset in minutes 
    const offsetMinutes  = dt.offset;

    const absOffsetMinutes = Math.abs(offsetMinutes);
    const hours = Math.floor(absOffsetMinutes / 60);
    const minutes = absOffsetMinutes % 60;

    const timeZoneGMTDiff = hours * 100 +minutes; // Convert to same format as original
    const isPos = offsetMinutes >= 0;
    
    return (isPos ? "+" : "-") + pad(timeZoneGMTDiff, 4);

  } catch (error) {
    return null;
  }
}

function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}
