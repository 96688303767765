export const ContactTypeEnum = {
  /// <summary>
  /// The super admin.
  /// </summary>
  SuperAdmin: 1,

  /// <summary>
  /// The admin.
  /// </summary>
  Admin: 2,

  /// <summary>
  /// The support engineer.
  /// </summary>
  Support: 3,

  /// <summary>
  /// The videographer.
  /// </summary>
  Videographer: 106,

  /// <summary>
  ///     The court reporter.
  /// </summary>
  CourtReporter: 103,

  /// <summary>
  ///     The firmMember.
  /// </summary>
  FirmMember: 6,

  /// <summary>
  ///     The participant.
  /// </summary>
  Participant: 7,

  /// <summary>
  ///     The ac admin.
  /// </summary>
  ACAdmin: 8,

  /// <summary>
  /// Any role
  /// </summary>
  Any: 100500,
};
