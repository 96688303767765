/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import { createTheme, adaptV4Theme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiPaper: { styleOverrides: { root: { backgroundImage: "unset" } } },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: { color: "#af1730" },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          marginLeft: 0,
          marginTop: 0,
        },
        tooltip: {
          backgroundColor: "pink",
          color: "#000000",
          boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#af1730",
    },
    secondary: {
      main: "#e4e4e4",
    },
    background: {
      default: "#ffffff",
    },
  },
  overrides: {
    MuiTab: {
      root: {
        overflow: "none",
        whiteSpace: "nowrap",
      },
    },
    MuiTextField: {
      root: {
        variant: "outlined",
        margin: 4,
      },
    },
  },
});

export default theme;
