/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { instance as http, AuthContext } from "@cirrux888/huseby-client-auth";
import { camelCase, find, forEach, get, merge } from "lodash";

const DELAY = 800;

let reducer = (data, newData) => {
  newData.clear && delete data[newData.clear] && delete newData.clear;
  return { ...merge(data, newData) };
};

const initialState = {
  myContact: {},
  timeZones: [],
};
const DataContext = React.createContext();

const DataProvider = (props) => {
  const [data, setData] = React.useReducer(reducer, initialState);
  const { auth, logout } = React.useContext(AuthContext);

  // helper function
  const assignDataToCase = (caseIds = [], caseData) => {
    forEach(caseIds, (c) => {
      const _case = find(data.cases, { caseId: c });
      merge(_case, caseData);
      setData({ cases: data.cases });
    });
  };

  //	1	Super Admin
  //	2	Admin
  //	3	Support
  //	4	Videographer
  //	5	Court Reporter
  //	6	Firm Member
  //	7	Participant
  //	8	ACAdmin
  const isAdminMode = () => {
    const myContact = JSON.parse(localStorage.getItem("huseby-contact"));

    const contactTypeId = myContact.contactTypeId;
    return contactTypeId === 1 || contactTypeId === 2 || contactTypeId === 3;
  };

  /**
   * Get the logged-in user's contact info.  This can be used to
   * hide/show different UI elements.
   *
   * @returns
   */
  const getMyContactInfo = () => {
    const myContact = JSON.parse(localStorage.getItem("huseby-contact"));
    return myContact;
  };

  const createTicket = async (ticketDetails) => {
    const config = {
      method: "post",
      url: `/contacts/support`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(ticketDetails),
    };
    try {
      setData({ loading: true });
      const { data: ticket } = await http(config);
      setData({ ticket });
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const lookup = async (path = "") => {
    const config = {
      method: "get",
      url: `/lookup/${path}`,
    };
    try {
      setData({ loading: true });
      const { data } = await http(config);
      setData({ lookup: { [camelCase(path)]: data } });

      if (path === "time-zones") {
        let timeZones = [];
        data.forEach((tz) => {
          timeZones[tz.timeZoneId] = tz;
        });
        setData({ timeZones: timeZones });
      }

      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const getApplicationConfig = async () => {
    console.log("Get application configs...");

    const appConfigs = {
      companyName: "Esquire",
      productName: "EsquireConnect",
      supportEmail: "support@esquiresolutions.com",
      supportEmail: "123-456-7890",
      bannerLogo: "",
      loginSplashImage: "",
      favIcon: "",
      // help docs
      userGuide: "",
      accessPortal: "",
      managingExhibits: "",
      navigatingLiveExhibits: "",
      presentingExhibits: "",
      quickReferenceGuide: "",
    };

    return appConfigs;

    // const config = {
    //   method: "get",
    //   url: `/app/config`,
    // };
    // try {
    //   setData({ loading: true });
    //   const { data: appConfig } = await http(config);
    //   setData({ appConfig });
    // } catch (error) {
    //   console.error(error);
    //   logout();
    // } finally {
    //   setTimeout(() => setData({ loading: false }), DELAY);
    // }
  };

  const getTimeZones = async () => {
    const config = {
      method: "get",
      url: "/hc/timezones",
    };
    try {
      setData({ loading: true });
      const { data: timeZones } = await http(config);
      setData({ timeZones, loading: false });
    } catch (error) {
      console.error(error);
      setData({ loading: false });
    }
  };

  return (
    <DataContext.Provider
      value={{
        data,
        setData,
        isAdminMode,
        getMyContactInfo,
        getTimeZones,
        createTicket,
        lookup,
        getApplicationConfig,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

const useDataService = () => React.useContext(DataContext);

export { DataContext, DataProvider, useDataService };
