/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { instance as http } from "@cirrux888/huseby-client-auth";
import { AuthContext } from "@cirrux888/huseby-client-auth";

const DELAY = 800;

const FirmContext = React.createContext();

let reducer = (data, newData) => {
  return { ...data, ...newData };
};

const initialState = {
  firms: [],
  firmNames: [],
  firmId: null,
  loading: false,
  error: false,
};

const FirmProvider = (props) => {
  const { auth } = React.useContext(AuthContext);
  const [data, setData] = React.useReducer(reducer, initialState);

  const listFirms = async (pageIndex = 0, pageLength = 25, query) => {
    const config = {
      method: "get",
      url: `/hc/firms?pageIndex=${pageIndex}&pageLength=${pageLength}&sortParam=firmName:asc`,
    };

    if (query) {
      query = encodeURIComponent(query);
      config.url += `&firmName=${query}`;
      // config.url += `&firmTypeId=${query}`;
      config.url += `&address1=${query}`;
      config.url += `&city=${query}`;
      config.url += `&state=${query}`;
      config.url += `&zip=${query}`;
      if (!isNaN(query)) config.url += `&rbFirmId=${query}`;
    }

    setData({ loading: true });
    try {
      const { data: firms } = await http(config);
      setData({
        firms: firms.content,
        listFirmParams: {
          // searchQuery,
          pagination: {
            totalElements: firms.totalElements,
            totalPages: firms.totalPages,
            pageNumber: firms.number,
          },
        },
        loading: false,
      });
      return firms;
    } catch (error) {
      setData({ firms: [], loading: false, error: true });
    }
  };

  const getFirm = async (firmId) => {
    const config = {
      method: "get",
      url: `/hc/firms/${firmId}`,
    };

    setData({ loading: true });
    try {
      const { data: firm } = await http(config);
      return firm;
    } catch (error) {
      setData({ loading: false, error: true });
    }
  };

  const listFirmNames = async () => {
    const config = {
      method: "get",
      url: `/hc/firms/names`,
    };

    setData({ loading: true });
    try {
      const { data: firms } = await http(config);
      setData({ firmNames: firms, loading: false });
      return firms;
    } catch (error) {
      setData({ firmsNames: [], loading: false, error: true });
    }
  };

  const getFirmContact = async (contactId) => {
    const config = {
      method: "get",
      url: `/hc/firms/contacts/${contactId}`,
    };

    setData({ loading: true });
    try {
      const { data } = await http(config);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const updateFirmContact = async (contactId, firmContactTypeId, firmId) => {
    const config = {
      method: "put",
      url: `/hc/firms/contacts/${contactId}`,
      data: {
        contactId,
        firmContactTypeId,
        firmId,
      },
    };

    setData({ loading: true });
    try {
      const { data } = await http(config);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const setFirmId = (firmId) => {
    setData({ firmId });
  };

  return (
    <FirmContext.Provider
      value={{
        ...data,
        setFirmId,
        listFirms,
        listFirmNames,
        getFirm,
        getFirmContact,
        updateFirmContact,
      }}
    >
      {props.children}
    </FirmContext.Provider>
  );
};

const useFirmService = () => React.useContext(FirmContext);

export { FirmContext, FirmProvider, useFirmService };
