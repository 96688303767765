import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Box, Dialog, DialogContent, Slide } from "@mui/material";
import {
    Index,
    ExhibitsProvider,
    FilePermissionsProvider,
} from "@cirrux888/huseby-client-exhibit-manager";
import { AuthProvider } from "@cirrux888/huseby-client-auth";
import { DataProvider } from "@cirrux888/huseby-client-exhibit-editor";
import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} timeout={500} />;
});
const ExhibitsManagement = () => {
    const location = useLocation();
    const history = useHistory();

    const folderId = location.search.split('=')[1];
    const eventId = location.pathname.split('/')[2]

    return (
        <>
            <Dialog
                fullScreen
                maxWidth="xl"
                open={true}
                onClose={() => {
                    history.push('/schedule');
                }}
                TransitionComponent={Transition}
            >
                <DialogContent
                    style={{
                        padding: 4,
                        backgroundColor: "#ffffff",
                    }}
                >
                    <Box
                        style={{
                            height: "100vh",
                            padding: 0,
                        }}
                    >
                        {(
                            <AuthProvider>
                                <ExhibitsProvider>
                                    <FilePermissionsProvider>
                                        <DataProvider>
                                            <Index
                                                eventId={eventId}
                                                onExhibitEditorLaunched={() => { }}
                                                onExhibitManagerClosed={() => {
                                                    history.push('/schedule');
                                                }}
                                                isMeetingRoom={false}
                                            />
                                        </DataProvider>
                                    </FilePermissionsProvider>
                                </ExhibitsProvider>
                            </AuthProvider>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ExhibitsManagement;