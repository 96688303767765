/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { instance as http } from "@cirrux888/huseby-client-auth";
import { merge } from "lodash";

let reducer = (data, newData) => {
  newData.clear && delete data[newData.clear] && delete newData.clear;
  return { ...merge(data, newData) };
};

const initialState = {
  taskList: [],
};

const RBContext = React.createContext();

const RBProvider = (props) => {
  const [data, setData] = React.useReducer(reducer, initialState);

  const listTasks = async ({ type = "all", query, pageIndex = 0, pageLength = 1000, sortParam = "dateEnd:desc" }) => {
    const config = {
      method: "get",
      url: `/rb/tasks?`,
    };

    if (query) {
      query = encodeURIComponent(query);
      config.url += `&taskId=${query}`;
    }

    if (type && type != "all") {
      config.url += `type=${type}`;
    }

    if (pageIndex) {
      config.url += `&pageIndex=${pageIndex}`;
    }

    if (pageLength) {
      config.url += `&pageLength=${pageLength}`;
    }

    if (sortParam) {
      config.url += `&sortParam=${sortParam}`;
    }

    // try {
    const { data: tasks } = await http(config);
    setData({
      tasks: tasks.content,
      listTaskParams: {
        // searchQuery,
        pagination: {
          totalElements: tasks.totalElements,
          totalPages: tasks.totalPages,
          pageNumber: tasks.number,
        },
      },
      loading: false,
    });

    return tasks;
  };

  const getTask = async (taskId) => {
    const config = {
      method: "get",
      url: `/rb/tasks/${taskId}`,
    };
    try {
      const { data: task } = await http(config);
      return task;
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  };

  /**
   * List imported RBFirms.
   *
   * @param {*} param0
   * @returns
   */
  const listFirms = async ({ query, taskId, pageIndex = 0, pageLength = 100, sortParam = "lastModified:desc" }) => {
    const config = {
      method: "get",
      url: `/rb/firms?`,
    };

    if (query) {
      query = encodeURIComponent(query);
      config.url += `&firmName=${query}`;
      config.url += `&salesforceId=${query}`;
    }

    if (taskId != null) {
      config.url += `&taskId=${taskId}`;
    }

    if (pageIndex) {
      config.url += `&pageIndex=${pageIndex}`;
    }

    if (pageLength) {
      config.url += `&pageLength=${pageLength}`;
    }

    if (sortParam) {
      config.url += `&sortParam=${sortParam}`;
    }

    try {
      const { data: firms } = await http(config);
      setData({
        firms: firms.content,
        listFirmParams: {
          // searchQuery,
          pagination: {
            totalElements: firms.totalElements,
            totalPages: firms.totalPages,
            pageNumber: firms.number,
          },
        },
        loading: false,
      });

      return firms;
    } catch (error) {
      console.error("error", { error });
      setData((prevState) => ({ ...prevState, firms: [], loading: false, error: true }));
      return [];
    }
  };

  /**
   * List merged RBFirms.
   *
   * @param {*} param0
   * @returns
   */
  const listMergedFirms = async ({ taskId, pageIndex = 0, pageLength = 100, sortParam = "mergedDate:desc" }) => {
    const config = {
      method: "get",
      url: `/rb/firms/merged?`,
    };

    if (taskId != null) {
      config.url += `taskId=${taskId}`;
    }

    if (pageIndex) {
      config.url += `&pageIndex=${pageIndex}`;
    }

    if (pageLength) {
      config.url += `&pageLength=${pageLength}`;
    }

    if (sortParam) {
      config.url += `&sortParam=${sortParam}`;
    }

    try {
      const { data: firms } = await http(config);
      setData({
        firms: firms.content,
        listFirmParams: {
          // searchQuery,
          pagination: {
            totalElements: firms.totalElements,
            totalPages: firms.totalPages,
            pageNumber: firms.number,
          },
        },
        loading: false,
      });

      return firms;
    } catch (error) {
      console.error("error", { error });
      setData((prevState) => ({ ...prevState, firms: [], loading: false, error: true }));
      return [];
    }
  };

  /**
   * List deleted RBFirms.
   *
   * @param {*} param0
   * @returns
   */
  const listDeletedFirms = async ({ taskId, pageIndex = 0, pageLength = 100, sortParam = "deletedDate:desc" }) => {
    const config = {
      method: "get",
      url: `/rb/firms/deleted?`,
    };

    if (taskId != null) {
      config.url += `taskId=${taskId}`;
    }

    if (pageIndex) {
      config.url += `&pageIndex=${pageIndex}`;
    }

    if (pageLength) {
      config.url += `&pageLength=${pageLength}`;
    }

    if (sortParam) {
      config.url += `&sortParam=${sortParam}`;
    }

    try {
      const { data: firms } = await http(config);
      setData({
        firms: firms.content,
        listFirmParams: {
          // searchQuery,
          pagination: {
            totalElements: firms.totalElements,
            totalPages: firms.totalPages,
            pageNumber: firms.number,
          },
        },
        loading: false,
      });

      return firms;
    } catch (error) {
      console.error("error", { error });
      setData((prevState) => ({ ...prevState, firms: [], loading: false, error: true }));
      return [];
    }
  };

  /**
   * List imported RBContacts.
   *
   * @param {*} param0
   * @returns
   */
  const listContacts = async ({ taskId, pageIndex = 0, pageLength = 100, sortParam = "lastModified:desc" }) => {
    const config = {
      method: "get",
      url: `/rb/contacts?`,
    };

    if (taskId != null) {
      config.url += `taskId=${taskId}`;
    }

    if (pageIndex) {
      config.url += `&pageIndex=${pageIndex}`;
    }

    if (pageLength) {
      config.url += `&pageLength=${pageLength}`;
    }

    if (sortParam) {
      config.url += `&sortParam=${sortParam}`;
    }

    try {
      const { data: contacts } = await http(config);
      setData({
        contacts: contacts.content,
        listFirmParams: {
          // searchQuery,
          pagination: {
            totalElements: contacts.totalElements,
            totalPages: contacts.totalPages,
            pageNumber: contacts.number,
          },
        },
        loading: false,
      });

      return contacts;
    } catch (error) {
      console.error("error", { error });
      setData((prevState) => ({ ...prevState, contacts: [], loading: false, error: true }));
      return [];
    }
  };

  /**
   * List merged RBContacts.
   *
   * @param {*} param0
   * @returns
   */
  const listMergedContacts = async ({ taskId, pageIndex = 0, pageLength = 100, sortParam = "mergedDate:desc" }) => {
    const config = {
      method: "get",
      url: `/rb/contacts/merged?`,
    };

    if (taskId != null) {
      config.url += `taskId=${taskId}`;
    }

    if (pageIndex) {
      config.url += `&pageIndex=${pageIndex}`;
    }

    if (pageLength) {
      config.url += `&pageLength=${pageLength}`;
    }

    if (sortParam) {
      config.url += `&sortParam=${sortParam}`;
    }

    try {
      const { data: contacts } = await http(config);
      setData({
        contacts: contacts.content,
        listFirmParams: {
          // searchQuery,
          pagination: {
            totalElements: contacts.totalElements,
            totalPages: contacts.totalPages,
            pageNumber: contacts.number,
          },
        },
        loading: false,
      });

      return contacts;
    } catch (error) {
      console.error("error", { error });
      setData((prevState) => ({ ...prevState, contacts: [], loading: false, error: true }));
      return [];
    }
  };

  /**
   * List deleted RBContacts.
   *
   * @param {*} param0
   * @returns
   */
  const listDeletedContacts = async ({ taskId, pageIndex = 0, pageLength = 100, sortParam = "deletedDate:desc" }) => {
    const config = {
      method: "get",
      url: `/rb/contacts/deleted?`,
    };

    if (taskId != null) {
      config.url += `taskId=${taskId}`;
    }

    if (pageIndex) {
      config.url += `&pageIndex=${pageIndex}`;
    }

    if (pageLength) {
      config.url += `&pageLength=${pageLength}`;
    }

    if (sortParam) {
      config.url += `&sortParam=${sortParam}`;
    }

    try {
      const { data: contacts } = await http(config);
      setData({
        contacts: contacts.content,
        listFirmParams: {
          // searchQuery,
          pagination: {
            totalElements: contacts.totalElements,
            totalPages: contacts.totalPages,
            pageNumber: contacts.number,
          },
        },
        loading: false,
      });

      return contacts;
    } catch (error) {
      console.error("error", { error });
      setData((prevState) => ({ ...prevState, contacts: [], loading: false, error: true }));
      return [];
    }
  };

  /**
   * List imported RBResources.
   *
   * @param {*} param0
   * @returns
   */
  const listResources = async ({ taskId, pageIndex = 0, pageLength = 100, sortParam = "lastModified:desc" }) => {
    const config = {
      method: "get",
      url: `/rb/resources?`,
    };

    if (taskId != null) {
      config.url += `taskId=${taskId}`;
    }

    if (pageIndex) {
      config.url += `&pageIndex=${pageIndex}`;
    }

    if (pageLength) {
      config.url += `&pageLength=${pageLength}`;
    }

    if (sortParam) {
      config.url += `&sortParam=${sortParam}`;
    }

    try {
      const { data: resources } = await http(config);
      setData({
        resources: resources.content,
        listFirmParams: {
          // searchQuery,
          pagination: {
            totalElements: resources.totalElements,
            totalPages: resources.totalPages,
            pageNumber: resources.number,
          },
        },
        loading: false,
      });

      return resources;
    } catch (error) {
      console.error("error", { error });
      setData((prevState) => ({ ...prevState, resources: [], loading: false, error: true }));
      return [];
    }
  };

  return (
    <RBContext.Provider
      value={{
        ...data,
        listTasks,
        listFirms,
        listMergedFirms,
        listDeletedFirms,
        listContacts,
        listMergedContacts,
        listDeletedContacts,
        listResources,
        getTask,
      }}
    >
      {props.children}
    </RBContext.Provider>
  );
};

const useRBService = () => React.useContext(RBContext);

export { RBProvider, useRBService };
