/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useEffect } from "react";
import { withStyles } from "@mui/styles";
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import { useHistory } from "react-router-dom";
import { AuthContext, AuthProvider, ResetPasswordDialog } from "@cirrux888/huseby-client-auth";
import LogoutIcon from "@mui/icons-material/PowerSettingsNew";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    minWidth: "250px",
    padding: "10px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const UserMenu = () => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [contactName, setContactName] = React.useState(null);
  const [contactEmail, setContactEmail] = React.useState(null);
  const [openResetPassword, setOpenResetPassword] = React.useState(false);
  const { logout } = React.useContext(AuthContext);
  const identityKey = "huseby-identity";

  useEffect(() => {
    let identity = JSON.parse(localStorage.getItem(identityKey));

    if (identity === null) return;

    setContactName(`${identity.firstName} ${identity.lastName}`);
    setContactEmail(`${identity.username}`);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResetPassword = () => {
    setOpenResetPassword(true);
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    history.push("/login");
  };

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <AccountCircleIcon color="primary" fontSize="large" />
      </IconButton>
      <StyledMenu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <Button
            variant="contained"
            className={classes.button}
            color="primary"
            startIcon={<LogoutIcon />}
            onClick={handleLogout}
          >
            Logout
          </Button> */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{ height: "100px" }}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <AccountCircleIcon color="primary" fontSize="large" />
          <Typography>{contactName}</Typography>
          <Typography>{contactEmail}</Typography>
        </Box>
        <Divider />
        <StyledMenuItem onClick={handleResetPassword}>
          <ListItemIcon>
            <LockIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText primary="Reset Password" />
        </StyledMenuItem>
        <Divider />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingTop="15px"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<LogoutIcon />}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </StyledMenu>

      {openResetPassword && (
        <AuthProvider>
          <ResetPasswordDialog
            open={openResetPassword}
            onClose={() => setOpenResetPassword(false)}
          />
        </AuthProvider>
      )}
    </>
  );
};

export default UserMenu;
