/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import Joi from "joi-browser";

export function validate (data, schema) {
    const options = {
      abortEarly: false,
    };
    const { error } = Joi.validate(data, schema, options);

    if (!error) return null;

    const errors = {};

    for (let item of error.details) 
        errors[item.path[0]] = item.message;

    return errors;
};

export function validateProperty (schema, {name, value}, setErrors ) {
    const obj = {
      [name]: value
    };
    const fieldSchema = {
        [name]: schema[name]
    };
    
    //return result
    const { error } = Joi.validate(obj, fieldSchema);
    if (!error) { 
      setErrorMessages(name, null, setErrors);
      return null;
    }
   
    setErrorMessages(name, error.details[0].message, setErrors);
};


export function setErrorMessages (field, errMsg, setErrors) { 
  setErrors(prevState => {
    let err = {...prevState};
    if (errMsg) {
      err[field] = errMsg;
    } else {
      delete err[field]
    }
    return err
  });
}
