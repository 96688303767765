/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Menu,
  MenuItem,
  ListItemText,
  Tabs,
  Tab,
  Paper,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import PhoneIcon from "@mui/icons-material/Phone";
import { AuthContext } from "@cirrux888/huseby-client-auth";
import HelpMenu from "./HelpMenu";
import UserMenu from "./UserMenu";
import { useDataService } from "services/DataService";
import { ContactTypeEnum } from "model/ContactTypeEnum";
import useBrand from "components/common/useBrand";
import { isNil } from "lodash";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    [`&:focus`]: {
      backgroundColor: theme.palette.primary.main,
      [`& .MuiListItemIcon-root, & .MuiListItemText-primary`]: {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: 10,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const { logout } = React.useContext(AuthContext);
  const { supportPhoneNumber } = useBrand();
  const { data } = useDataService();
  const defaultRoute = location.pathname.substring(1) || "schedule";
  const [enableCaseTab, setEnableCaseTab] = React.useState(false);
  const [enableUserTab, setEnableUserTab] = React.useState(false);
  const [adminEl, setAdminEl] = React.useState(null);

  React.useEffect(() => {
    if (isNil(data.myContact)) return;
    const contactTypeId = data.myContact.contactTypeId;
    if (
      contactTypeId === ContactTypeEnum.SuperAdmin ||
      contactTypeId === ContactTypeEnum.Admin ||
      contactTypeId === ContactTypeEnum.Support ||
      contactTypeId === ContactTypeEnum.FirmMember
    ) {
      setEnableCaseTab(true);
    } else {
      setEnableCaseTab(false);
    }

    if (
      contactTypeId === ContactTypeEnum.SuperAdmin ||
      contactTypeId === ContactTypeEnum.Admin ||
      contactTypeId === ContactTypeEnum.Support
    ) {
      setEnableUserTab(true);
    } else {
      setEnableUserTab(false);
    }
  }, [data]);

  const handleChange = (event, route) => {
    history.push(`/${route}`);
  };

  const handleAdminMenuSelected = (selected) => {
    setAdminEl(null);
    history.push(selected);
  };

  const handleAdminClick = (event) => {
    setAdminEl(event.currentTarget);
  };

  const handleAdminClose = () => {
    setAdminEl(null);
  };

  return (
    <Paper>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          p={1}
          className="headerLogo"
          height={50}
          width={400}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <img src={`/${process.env.REACT_APP_BRAND_ID}/images/logo.png`} alt="Logo" height="40" />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" justify="flex-end" mr={2}>
          <PhoneIcon />
          <Box style={{ fontSize: "18pt" }} mr={4}>
            <a href={`tel:${supportPhoneNumber?.tel}`} className="supportPhone">
              {supportPhoneNumber?.label}
            </a>
          </Box>
          <HelpMenu />
          <UserMenu />
        </Box>
      </Box>
      <AppBar position="static" enableColorOnDark>
        <Tabs value={defaultRoute}
          indicatorColor="secondary" textColor="inherit"
          onChange={handleChange}
          sx={{ gap: '40px' }}
        >
          <Tab
            value="schedule"
            label="SCHEDULE"
            {...a11yProps("schedule")}
            component={Link}
            to="/schedule"
          />
          {enableCaseTab === true && (
            <Tab
              value="cases"
              label="CASE MANAGEMENT"
              {...a11yProps("cases")}
              component={Link}
              to="/cases"
            />
          )}
          {enableUserTab === true && (
            <Tab
              value="admin"
              label="ADMINISTRATION"
              {...a11yProps("admin")}
              onClick={handleAdminClick}
            />
          )}
        </Tabs>

        <StyledMenu
          id="customized-menu"
          anchorEl={adminEl}
          keepMounted
          open={Boolean(adminEl)}
          onClose={handleAdminClose}
          disableAutoFocusItem
        >
          <StyledMenuItem onClick={() => handleAdminMenuSelected("/admin/schedule")}>
            <ListItemText primary="Schedule" />
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleAdminMenuSelected("/admin/firms")}>
            <ListItemText primary="Firms" />
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleAdminMenuSelected("/admin/firmMembers")}>
            <ListItemText primary="Firm Members" />
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleAdminMenuSelected("/admin/participants")}>
            <ListItemText primary="Participants" />
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleAdminMenuSelected("/admin/internal")}>
            <ListItemText primary="Internal Users" />
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleAdminMenuSelected("/admin/resources")}>
            <ListItemText primary="Resources" />
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleAdminMenuSelected("/admin/tasks")}>
            <ListItemText primary="Tasks" />
          </StyledMenuItem>
        </StyledMenu>
      </AppBar>
    </Paper>
  );
};

export default Header;
