/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { instance as http } from "@cirrux888/huseby-client-auth";
import { merge } from "lodash";
import { AuthContext } from "@cirrux888/huseby-client-auth";

const DELAY = 800;

let reducer = (data, newData) => {
  newData.clear && delete data[newData.clear] && delete newData.clear;
  return { ...merge(data, newData) };
};

const initialState = {};
const UserContext = React.createContext();

const UserProvider = (props) => {
  const [data, setData] = React.useReducer(reducer, initialState);
  const { auth, logout } = React.useContext(AuthContext);

  const listUsers = async (pageIndex = 0, pageLength = 25, query, contactTypeId) => {
    console.log("UserService.listUsers...", pageIndex, pageLength, query);
    const config = {
      method: "get",
      url: `/users?pageIndex=${pageIndex}&pageLength=${pageLength}&sortParam=firstName:asc`,
    };

    if (query) {
      config.url += `&firstName=${query}&lastName=${query}&username=${query}&name=${query}`;
    }

    if (contactTypeId) {
      config.url += `&contactTypeId=${contactTypeId}`;
    }

    try {
      setData({ loading: true });
      // const { data: users } = await http(config);
      // setData({ users: users.content });
      // return users.content;

      const { data: users } = await http(config);
      setData({
        users: users.content,
        listUserParams: {
          // searchQuery,
          pagination: {
            totalElements: users.totalElements,
            totalPages: users.totalPages,
            pageNumber: users.number,
          },
        },
        loading: false,
      });
      return users;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const createUser = async ({ firstName, lastName, email, contactTypeId, password, passwordVerify }) => {
    console.log("UserService.createUser...");
    const config = {
      method: "post",
      url: `/users`,
      data: {
        firstName,
        lastName,
        username: email,
        contactTypeId,
        password,
        passwordVerify,
      },
    };

    try {
      setData({ loading: true });
      const { data: user } = await http(config);
      return user;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const activateUser = async ({ contactId, username }) => {
    console.log("UserService.activateUser...");
    const config = {
      method: "put",
      url: `/users/${contactId}/activate?username=${username}`,
    };

    try {
      setData({ loading: true });
      const { data: user } = await http(config);
      return user;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const getUser = async (userId) => {
    console.log("UserService.getUser...");
    const config = {
      method: "get",
      url: `/users/${userId}`,
    };
    try {
      setData({ loading: true });
      const { data } = await http(config);
      setData({ user: data });
      return data;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const getUserByContactId = async (contactId) => {
    console.log("UserService.getUserByContactId...");
    const config = {
      method: "get",
      url: `/users/by-contact?contactId=${contactId}`,
    };
    try {
      setData({ loading: true });
      const { data } = await http(config);
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const updateUser = async ({
    userId,
    firstName,
    lastName,
    email,
    username,
    contactTypeId,
    password,
    passwordVerify,
  }) => {
    console.log("UserService.updateUser...");
    const config = {
      method: "put",
      url: `/users/${userId}`,
      data: {
        firstName,
        lastName,
        username,
        email,
        contactTypeId,
      },
    };

    try {
      setData({ loading: true });
      const { data: user } = await http(config);
      return user;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Delete the user.
   *
   * @param {*} userId
   */
  const deleteUser = async (userId) => {
    console.log("UserService.deleteUser...", userId);
    const config = {
      method: "delete",
      url: `/users/${userId}`,
    };
    try {
      setData({ loading: true });
      const { data } = await http(config);
      // QW-XXX: Remove from UI
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const sendPasswordResetEmail = async (userId) => {
    console.log("UserService.sendPasswordResetEmail...", userId);
    const config = {
      method: "get",
      url: `/users/${userId}/send-reset-password`,
    };
    try {
      setData({ loading: true });
      const { data } = await http(config);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  return (
    <UserContext.Provider
      value={{
        data,
        setData,
        listUsers,
        createUser,
        activateUser,
        getUser,
        getUserByContactId,
        updateUser,
        deleteUser,
        sendPasswordResetEmail,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

const useUserService = () => React.useContext(UserContext);

export { UserContext, UserProvider, useUserService };
