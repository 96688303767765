/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { Switch, Route } from "react-router-dom";
import Index from "./components/Index";
import { Login } from "@cirrux888/huseby-client-auth";
import Invitation from "./components/common/Invitation";
import { PrivateRoute, ResetPassword } from "@cirrux888/huseby-client-auth";
import { RecordingPlayer, RecordingsProvider } from "@cirrux888/huseby-client-exhibit-manager";
import CacheBuster from "./CacheBuster";

const App = () => {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        console.log("CacheBuster.loading", loading);
        console.log("CacheBuster.isLatestVersion", isLatestVersion);
        console.log("SharedArrayBuffer enabled: ", typeof SharedArrayBuffer === "function");

        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }
        return (
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/decline-invitation">
              <Invitation />
            </Route>
            <Route path="/accept-invitation">
              <Invitation />
            </Route>
            <Route path="/reset">
              <ResetPassword />
            </Route>
            <PrivateRoute exact path="/recordings/:recordingId">
              <RecordingsProvider>
                <RecordingPlayer />
              </RecordingsProvider>
            </PrivateRoute>
            <PrivateRoute path="/">
              <Index />
            </PrivateRoute>
          </Switch>
        );
      }}
    </CacheBuster>
  );
};

export default App;
