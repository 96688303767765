/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { Box, CssBaseline, Grid, Link, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";
import { DataContext } from "../../services/DataService";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/login_splash3.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.mode === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export default function Invitation() {
  const classes = useStyles();
  const location = useLocation();
  const { acceptInvitation, declineInvitation } = React.useContext(DataContext);
  const [action, setAction] = React.useState("accept-invitation");
  const [from, setFrom] = React.useState(null);
  const [eventCode, setEventCode] = React.useState(null);
  const [to, setTo] = React.useState(null);

  React.useEffect(() => {
    console.log("Invitation.location", location);
    let path = location.pathname.split("/");
    console.log("path", path);
    console.log("action", path[0]);
    console.log("from", path[1]);
    console.log("eventCode", path[2]);
    console.log("to", path[3]);

    setAction(path[0]);
    setFrom(path[1]);
    setEventCode(path[2]);
    setTo(path[3]);
    async function sendInviteResponse() {
      if (path[0] === "accept-invitation") {
        // await acceptInvitation(from, eventCode, to);
      } else {
        // await declineInvitation(from, eventCode, to);
      }
    }

    sendInviteResponse();
  }, [location]);

  return (
    <>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Box mt={10} mb={10}>
              <img src="/images/logo.png" alt="Logo" height="40" />
            </Box>
            {action === "accept-invitation" ? (
              <span>Thank you for accepting our invitation</span>
            ) : (
              <span>We're sorry you can't attend. We look forward to seeing you next time.</span>
            )}
            <Box mt={5}>
              <Copyright />
            </Box>
          </div>
        </Grid>
      </Grid>
    </>
  );

  const Copyright = () => {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link
          color="inherit"
          href="https://www.huseby.com/remote-depositions"
          underline="hover">
          Huseby
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  };
}
