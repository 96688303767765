/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { instance as http } from "@cirrux888/huseby-client-auth";
import { merge } from "lodash";

let reducer = (data, newData) => {
  newData.clear && delete data[newData.clear] && delete newData.clear;
  return { ...merge(data, newData) };
};

const initialState = {
  courtReporters: [],
  internalContacts: [],
  firmContacts: [],
  participants: [],
};

const ContactContext = React.createContext();

const ContactProvider = (props) => {
  const [data, setData] = React.useReducer(reducer, initialState);

  const listInternalContacts = async ({ pageIndex = 0, pageLength = 1000, query, contactTypeId, status = -1 }) => {
    const _contacts = await listContacts({
      pageIndex,
      pageLength,
      firstName: query,
      lastName: query,
      email: query,
      contactType: "internals",
      contactTypeId,
      status,
    });
    setData({ internalContacts: _contacts });
    return _contacts;
  };

  const listFirmMembers = async ({ pageIndex = 0, pageLength = 1000, query, firmId, status }) => {
    const payload = {
      pageIndex,
      pageLength,
      firstName: query,
      lastName: query,
      email: query,
      firmName: query,
      firmId,
      contactType: "firm-contacts",
      rbNumber: query,
      firmContactType: query,
      status,
    };
    if (isNaN(+query)) delete payload.rbNumber;
    const _contacts = await listContacts(payload);
    setData({ firmContacts: _contacts });
    return _contacts;
  };

  const listResources = async ({ pageIndex = 0, pageLength = 1000, query, status }) => {
    let _firstName = query;
    let _lastName = query;
    let _matchAll = false;

    if (query && query.indexOf(" ") != -1) {
      _firstName = query.split(" ")[0];
      _lastName = query.split(" ")[1];
      _matchAll = true;
      query = "";
    }
    const _contacts = listContacts({
      pageIndex,
      pageLength,
      firstName: _firstName,
      lastName: _lastName,
      email: query,
      email2: query,
      rbNumber: query,
      contactType: "resources",
      matchAll: _matchAll,
      status,
    });
    setData({ resources: _contacts });
    return _contacts;
  };

  const listParticipants = async ({ pageIndex = 0, pageLength = 1000, query, status }) => {
    const _contacts = listContacts({
      pageIndex,
      pageLength,
      firstName: query,
      lastName: query,
      email: query,
      contactType: "participants",
      status,
    });
    setData({ participants: _contacts });
    return _contacts;
  };

  const listContacts = async ({
    pageIndex = 0,
    pageLength = 1000,
    firstName,
    lastName,
    email,
    email2,
    firmName,
    firmId,
    contactType,
    contactTypeId,
    rbNumber,
    firmContactType,
    matchAll,
    status = -1,
  }) => {
    const config = {
      method: "get",
      url: `/hc/contacts`,
    };

    if (contactType) {
      config.url += `/${contactType}?`;
    } else {
      config.url += `?`;
    }

    if (pageIndex) {
      config.url += `&pageIndex=${pageIndex}`;
    }

    if (pageLength) {
      config.url += `&pageLength=${pageLength}`;
    }

    if (contactTypeId) {
      config.url += `&contactTypeId=${contactTypeId}`;
    }

    if (firstName) {
      config.url += `&firstName=${encodeURIComponent(firstName)}`;
      // config.url += `&name=${firstName}`;
    }

    if (firstName || lastName) {
      // config.url += `&firstName=${firstName}`;
      config.url += `&name=${encodeURIComponent(firstName)}`;
    }

    if (config.url.indexOf("?") != -1) config.url += "&pageLength=1000";
    else config.url += "?pageLength=1000";
    if (lastName) {
      config.url += `&lastName=${encodeURIComponent(lastName)}`;
      // config.url += `&name=${lastName}`;
    }

    if (email) {
      config.url += `&email=${encodeURIComponent(email)}`;
    }

    if (email2) {
      config.url += `&email2=${encodeURIComponent(email2)}`;
    }

    // Default email2 to email if no email2 parameter is found.
    if (email && !email2) {
      config.url += `&email2=${encodeURIComponent(email)}`;
    }

    if (firmName) {
      config.url += `&firmName=${encodeURIComponent(firmName)}`;
    }

    if (firmId) {
      config.url += `&firmId=${firmId}`;
    }

    if (rbNumber && !isNaN(rbNumber)) {
      config.url += `&rbNumber=${rbNumber}`;
    }

    if (status) {
      config.url += `&status=${status}`;
    }

    if (firmContactType) {
      config.url += `&firmContactType=${encodeURIComponent(firmContactType)}`;
    }

    if (matchAll) {
      config.url += `&matchAll=${matchAll}`;
    }

    try {
      const { data: contacts } = await http(config);
      setData({
        contacts: contacts.content,
        listUserParams: {
          // searchQuery,
          pagination: {
            totalElements: contacts.totalElements,
            totalPages: contacts.totalPages,
            pageNumber: contacts.number,
          },
        },
        loading: false,
      });

      return contacts;
    } catch (error) {
      console.error("error", { error });
      setData((prevState) => ({ ...prevState, contacts: [], loading: false, error: true }));
      return [];
    }
  };

  const getContact = async (contactId) => {
    const config = {
      method: "get",
      url: `/hc/contacts/${contactId}`,
    };
    try {
      const { data: contact } = await http(config);
      return contact;
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  };

  const createContact = async ({ firstName, lastName, officePhone, mobilePhone, email, password, contactTypeId }) => {
    console.log("ContactService.createContact", firstName, lastName);
    const config = {
      method: "post",
      url: `/hc/contacts`,
      data: {
        firstName,
        lastName,
        officePhone,
        mobilePhone,
        email,
        password,
        contactTypeId,
      },
    };
    try {
      const { data: contact } = await http(config);
      return contact;
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  };

  const listExternalCourtReporters = async ({ search }) => {
    const config = {
      method: "get",
      url: `/hc/contacts/external-court-reporters`,
    };

    if (search) {
      config.url += `&firstName=${search}&lastName=${search}&email=${search}`;
    }

    try {
      setData((prevState) => ({ ...prevState, loading: true }));
      const { data: contactData } = await http(config);
      setData((prevState) => ({
        ...prevState,
        courtReporters: contactData.content,
        loading: false,
      }));
      return contactData.content;
    } catch (error) {
      console.error("error", { error });
      setData((prevState) => ({
        ...prevState,
        contacts: [],
        loading: false,
        error: true,
      }));
      return [];
    }
  };

  /**
   * List StreamText writers / court reporters.
   *
   * @returns
   */
  const listStreamTextWriters = async ({ search }) => {
    console.log("Listing StreamText writers...");

    const config = {
      method: "get",
      url: `st/users/writers`,
    };

    try {
      setData({ loading: true });
      const { data: listData } = await http(config);
      setData({ loading: false, streamTextWriters: data });
      console.log("StreamTextService.listStreamTextWriters data is", listData);
      return listData;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ContactContext.Provider
      value={{
        ...data,
        listContacts,
        listInternalContacts,
        listFirmMembers,
        listResources,
        listParticipants,
        getContact,
        createContact,
        listExternalCourtReporters,
        listStreamTextWriters,
      }}
    >
      {props.children}
    </ContactContext.Provider>
  );
};

const useContactService = () => React.useContext(ContactContext);

export { ContactProvider, useContactService };
