/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { instance as http, useAuthService } from "@cirrux888/huseby-client-auth";
import { get, merge } from "lodash";
import { DataContext } from "./DataService";

const DELAY = 800;

let reducer = (data, newData) => {
  return { ...data, ...newData };
};

const initialState = {
  cases: [],
  firmId: "",
  participants: [],
  listCaseParams: {
    searchQuery: "",
    pagination: {},
  },
  firmId: null,
  loading: false,
  error: false,
  case: {},
};

const CaseContext = React.createContext();

const CaseProvider = (props) => {
  const { auth } = useAuthService();
  const [data, setData] = React.useReducer(reducer, initialState);

  // QW-TODO: Need to refactor this and use from DataService.
  const isAdminMode = () => {
    const myContact = JSON.parse(localStorage.getItem("huseby-contact"));

    const contactTypeId = myContact.contactTypeId;
    return contactTypeId === 1 || contactTypeId === 2 || contactTypeId === 3;
  };

  /** Case Services */
  const listCases = async ({
    firmId,
    contactId = get(auth, ["identity", "contactId"]),
    searchQuery = "",
    pageNumber = "0",
    pageSize = "10",
  } = {}) => {
    if (isAdminMode()) {
      listFirmCases({ firmId, contactId, searchQuery, pageNumber, pageSize });
    } else {
      listMyCases({ contactId, searchQuery, pageNumber, pageSize });
    }
  };

  const listFirmCases = async ({
    firmId,
    contactId = get(auth, ["identity", "contactId"]),
    searchQuery = "",
    pageNumber = "0",
    pageSize = "1000",
  } = {}) => {
    console.log("List firm cases...", firmId, contactId);
    const config = {
      method: "get",
      url: `/hc/cases?pageIndex=${pageNumber}&pageLength=${pageSize}&sortParam=caseName:asc`,
    };

    if (firmId) {
      config.url += `&firmId=${firmId}`;
    }
    if (searchQuery) {
      config.url += `&caseName=${searchQuery}`;
    }

    setData({ loading: true });
    try {
      const { data: cases } = await http(config);
      setData({
        cases: cases.content,
        listCaseParams: {
          searchQuery,
          pagination: {
            totalPages: cases.totalPages,
            pageNumber: cases.number,
          },
        },
        loading: false,
      });
      return cases;
    } catch (error) {
      setData({ error: true, loading: false });
    }
  };

  const listMyCases = async ({
    contactId = get(auth, ["identity", "contactId"]),
    searchQuery = "",
    pageNumber = "0",
    pageSize = "10",
  } = {}) => {
    console.log("List my cases...", contactId);
    const config = {
      method: "get",
      url: `/hc/cases/my?pageIndex=${pageNumber}&pageLength=${pageSize}&sortParam=caseName:asc`,
    };

    if (searchQuery) {
      config.url += `&caseName=${searchQuery}`;
    }

    setData({ loading: true });
    try {
      const { data: cases } = await http(config);
      setData({
        cases: cases.content,
        listCaseParams: {
          searchQuery,
          pagination: {
            totalPages: cases.totalPages,
            pageNumber: cases.number,
          },
        },
        loading: false,
      });
      return cases;
    } catch (error) {
      setData({ error: true, loading: false });
    }
  };

  const createCase = async ({
    caseName,
    remarks,
    firmId,
    recordAll,
    transcripts,
    exhibitNumbering,
    passCode,
    enableGuestAccess,
    exhibits,
    enableMeeting,
    allowJoinBeforeHost,
  }) => {
    console.log("CaseService.createCase()");
    const config = {
      method: "post",
      url: `/hc/cases`,
      data: {
        caseName,
        details: remarks,
        firm: {
          firmId,
        },
        isRecordingEnabled: recordAll,
        isExhibitsNumbersAutoIncremented: exhibitNumbering,
        transcripts: Number(transcripts),
        isPasscodeEnabled: passCode,
        enableGuestAccess,
        exhibits,
        enableMeeting,
        allowJoinBeforeHost,
      },
    };

    setData({ loading: true });
    try {
      const { data: newCase } = await http(config);
      setData({ loading: false });
      return newCase;
    } catch (error) {
      setData({ loading: false, error: true });
      throw error;
    }
  };

  const getCase = async (caseId) => {
    const config = {
      method: "get",
      url: `/hc/cases/${caseId}`,
    };
    try {
      setData({ loading: true, error: "" });
      const { data: caseData } = await http(config);
      const existentCaseIndex = data.cases.findIndex((c) => c.caseId === caseData.caseId);
      const updatedCaseData = [...data.cases];

      if (existentCaseIndex > -1) {
        updatedCaseData[existentCaseIndex] = { ...caseData };
      } else {
        updatedCaseData.unshift({ ...caseData });
      }

      setData({
        case: { ...caseData },
        cases: updatedCaseData,
        firm: caseData.firm,
        loading: false,
        error: "",
      });

      return caseData;
    } catch (error) {
      console.error(error);
      setData({ loading: false, error: "case not found" });
      return error;
    }
  };

  const updateCase = async ({
    caseId,
    caseName,
    remarks,
    recordAll,
    transcripts,
    exhibitNumbering,
    passCode,
    enableGuestAccess,
    exhibits,
    enableMeeting,
    allowJoinBeforeHost,
  }) => {
    console.log("CaseService.updateCase()");
    const config = {
      method: "put",
      url: `/hc/cases/${caseId}`,
      data: {
        caseId,
        caseName,
        details: remarks,
        isRecordingEnabled: recordAll,
        isExhibitsNumbersAutoIncremented: exhibitNumbering,
        transcripts: Number(transcripts),
        isPasscodeEnabled: passCode,
        enableGuestAccess,
        exhibits,
        enableMeeting,
        allowJoinBeforeHost,
      },
    };
    try {
      setData({ loading: true });
      const { data: apiData } = await http(config);
      setData({ case: apiData, loading: false, error: false });
      console.log({ caseId, apiData });

      // Update the case in the cases list.
      const updatedCases = data?.cases?.map((c) => {
        if (c?.caseId === caseId) {
          return apiData;
        } else {
          return c;
        }
      });
      setData({ cases: updatedCases });

      return data;
    } catch (error) {
      console.log({ error });
      setData({ loading: false, error });
      throw error;
    }
  };

  const deleteCase = async (caseId) => {
    console.log("CaseService.deleteCase()");
    const config = {
      method: "delete",
      url: `/hc/cases/${caseId}`,
    };
    setData({ loading: true });
    try {
      await http(config);
      const updatedCases = data.cases.filter((c) => c?.caseId !== caseId);
      setData({ loading: false, cases: updatedCases });
      return true;
    } catch (error) {
      setData({ loading: false, error: true });
      throw error;
    }
  };

  const listCaseParticipants = async (caseId) => {
    console.log("CaseService.listCaseParticipants()", caseId);
    const config = {
      method: "get",
      url: `/hc/cases/${caseId}/participants`,
    };

    try {
      const { data } = await http(config);
      setData({ loading: false, participants: data });
      return data;
    } catch (error) {
      setData({ loading: false, error: true });
      throw error;
    }
  };

  const addCaseParticipant = async ({ caseId, contactId, eventParticipantTypeId }) => {
    console.log("CaseService.addCasePartcipant()", caseId);
    const config = {
      method: "post",
      url: `/hc/cases/${caseId}/participants`,
      data: {
        contactId,
        eventParticipantTypeId,
        grantAccessToRecordings: 1,
      },
    };

    setData({ loading: true });

    try {
      const { data: participantData } = await http(config);
      console.log({ participantData });
      const newParticipants = [...data?.participants, participantData];
      setData({ loading: false, participants: newParticipants });
      console.log({ data, newParticipants }, data.participants);
      return data;
    } catch (error) {
      setData({ loading: false, error: true });
      throw error;
    }
  };

  const removeCaseParticipant = async ({ caseId, contactId }) => {
    console.log("CaseService.removeCasePartcipant()");
    const config = {
      method: "delete",
      url: `/hc/cases/${caseId}/participants/${contactId}`,
    };
    try {
      await http(config);
      const updatedParticpants = data.participants.filter(
        (participant) => participant?.contact?.contactId !== contactId
      );
      setData({ loading: false, participants: updatedParticpants });
      return data;
    } catch (error) {
      setData({ loading: false, error: true });
      throw error;
    }
  };

  const updateCaseParticipant = async (caseId, participant) => {
    console.log("CaseService.updateCasePartcipant()", caseId, participant);
    const config = {
      method: "put",
      url: `/hc/cases/${caseId}/participants`,
      data: participant, // contactId, contactParticipantTypeId, grantAccessToRecordings
    };
  };

  const inviteCaseParticipant = async () => {
    console.log("CaseService.inviteCasePartcipant()");
  };

  const clearCases = () => setData({ cases: [], loading: false, error: null });

  const clearCase = () => setData({ case: {} });

  /** End of Case Services */
  return (
    <CaseContext.Provider
      value={{
        ...data,
        listCases,
        // listMyCases,
        createCase,
        getCase,
        clearCase,
        updateCase,
        deleteCase,
        listCaseParticipants,
        addCaseParticipant,
        removeCaseParticipant,
        updateCaseParticipant,
        inviteCaseParticipant,
        clearCases,
      }}
    >
      {props.children}
    </CaseContext.Provider>
  );
};

const useCaseService = () => React.useContext(CaseContext);

export { CaseContext, CaseProvider, useCaseService };
