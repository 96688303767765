/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { withStyles } from "@mui/styles";
import { Divider, IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import ChatIcon from "@mui/icons-material/Chat";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { DataContext } from "../../services/DataService";
import SupportTicket from "../support/SupportTicket";
import About from "../support/About";
import useBrand from "./useBrand";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const HelpMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSupportTicket, setOpenSupportTicket] = React.useState(false);
  const [openAbout, setOpenAbout] = React.useState(false);
  const { productName } = useBrand();
  const { data } = React.useContext(DataContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLiveChat = () => {
    handleClose();

    // let identity = JSON.parse(localStorage.getItem(identityKey));
    // const liveChatUrl = data.appConfig.liveChatUrl;
    // const swfUrl = data.appConfig.exhibitToolPracticeModeURL;
    // const contactName = `${identity.firstName} ${identity.lastName}`;
    // const contactEmail = `${identity.username}`;
    // console.log("handleLiveChat()", `${liveChatUrl}=true&cbdid=5713967259447396852&url=${swfUrl}&vn=${contactName}&ve=${contactEmail}&vp=`);
    // window.open(
    // `${liveChatUrl}=true&cbdid=5713967259447396852&url=${swfUrl}&vn=${contactName}&ve=${contactEmail}&vp=`
    // );
    window.open("https://direct.lc.chat/7121041/");
  };

  const handleLaunchUserGuide = () => {
    handleClose();

    const supportDocumentView = data.appConfig.supportDocumentView;
    window.open(supportDocumentView);
  };

  const handleLaunchPdf = (pdf) => {
    handleClose();

    // Add brand path for whitelabeling
    pdf = "/" + process.env.REACT_APP_BRAND_ID + pdf;

    window.open(pdf);
  };

  return (
    <>

      {/* <Button variant="contained" color="primary" startIcon={<HelpIcon />} onClick={handleClick}>
        Help
      </Button> */}
      <IconButton variant="contained" size="small" onClick={handleClick}>
        <HelpIcon fontSize="large" color="primary" />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableAutoFocusItem
      >
        <StyledMenuItem onClick={() => handleLaunchPdf("/pdfs/User Guide.pdf")}>
          <ListItemIcon>
            <PictureAsPdfIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText primary="User Guide" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleLaunchPdf("/pdfs/Accessing HC Portal.pdf")}>
          <ListItemIcon>
            <PictureAsPdfIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText primary="Accessing Your Portal" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleLaunchPdf("/pdfs/Manage Exhibits Module.pdf")}>
          <ListItemIcon>
            <PictureAsPdfIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText primary="Managing Exhibits" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleLaunchPdf("/pdfs/Your Live Event.pdf")}>
          <ListItemIcon>
            <PictureAsPdfIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText primary="Navigating Your Live Event" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleLaunchPdf("/pdfs/Presenting Exhibits in Live Event.pdf")}>
          <ListItemIcon>
            <PictureAsPdfIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText primary="Presenting Exhibits" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleLaunchPdf("/pdfs/Quick Reference Guide.pdf")}>
          <ListItemIcon>
            <PictureAsPdfIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText primary="Quick Reference Guide" />
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem
          onClick={() => {
            handleClose();
            setOpenAbout(true);
          }}
        >
          <ListItemIcon>
            <LiveHelpIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText primary={`About ${productName}`} />
        </StyledMenuItem>
      </StyledMenu>

      <SupportTicket
        open={openSupportTicket}
        handleClose={() => {
          setOpenSupportTicket(false);
        }}
      />
      <About open={openAbout} handleClose={() => setOpenAbout(false)} />
    </>
  );
};

export default HelpMenu;
