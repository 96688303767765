/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { instance as http } from "@cirrux888/huseby-client-auth";
import { find, forEach, get, merge } from "lodash";
import moment from "moment";
import { filter } from "lodash";
import { AuthContext } from "@cirrux888/huseby-client-auth";
import { DataContext } from "./DataService";
import { isBeforeToday } from "../components/common/dayUtilities";

const DELAY = 800;
const FILTER_PAST_EVENTS = true;

const tokenKey = "huseby-token";
const identityKey = "huseby-identity";
const refreshTokenKey = "huseby-refresh";

const EventContext = React.createContext();

let reducer = (data, newData) => {
  return { ...data, ...newData };
};

const initialState = {
  events: [],
  participants: [],
  listEventParams: {
    searchQuery: "",
    pagination: {},
  },
  loading: false,
  error: false,
};

const EventProvider = (props) => {
  const { auth, setLoginCookies, logout } = React.useContext(AuthContext);
  const [data, setData] = React.useReducer(reducer, initialState);

  // helper function
  const assignDataToCase = (caseIds = [], caseData) => {
    forEach(caseIds, (c) => {
      const _case = find(data.cases, { caseId: c });
      merge(_case, caseData);
      setData({ cases: data.cases });
    });
  };

  const listCaseEvents = async ({
    caseId = -1,
    startDate = new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
    endDate = new Date(2210, 1, 1, 0, 0, 0).toISOString(),
  }) => {
    const config = {
      method: "get",
      url: `/hc/events?pageLength=1000&startDateFrom=${startDate}&startDateTo=${endDate}`,
    };
    if (caseId > -1) {
      config.url += `&caseId=${caseId}`;
    }
    setData({ loading: true });

    try {
      const { data: events } = await http(config);
      setData({
        events: events.content,
        listCaseParams: {
          pagination: {
            totalPages: events.totalPages,
            pageNumber: events.number,
          },
        },
        loading: false,
      });
      return events.content;
    } catch (error) {
      setData({ error: true, loading: false });
      console.error(error);
      return error;
    }
  };

  const listContactEvents = async (
    { contactId = get(auth, ["identity", "contactId"]), pageNumber = "1", pageSize = "10" } = {},
    clearCache = true
  ) => {
    console.log("Get contact events...");
    const config = {
      method: "get",
      url: `/events/by-contacts?contactId=${contactId}&pageIndex=${pageNumber}&pageSize=${pageSize}`,
    };
    clearCache && setData({ clear: "contactEvents" });
    try {
      setData({ loading: true });
      const { data: contactEvents } = await http(config);

      // Filter out Events that occur before today.
      if (FILTER_PAST_EVENTS) {
        const filteredEvents = filter(contactEvents, (e) => !isBeforeToday(e.startDate));
        contactEvents = filteredEvents;
      }
      setData({ contactEvents });
      return contactEvents;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const listUpcomingEvents = async ({
    pageIndex = 0,
    pageLength = 25,
    eventName = null,
    rbJobId = null,
    startDate = moment(new Date().setHours(0, 0, 0, 0)).format("YYYY-MM-DD"),
    endDate = moment(new Date(2210, 1, 1, 0, 0, 0)).format("YYYY-MM-DD"),
  }) => {
    let url = `/hc/events/upcoming?pageIndex=${pageIndex}&pageLength=${pageLength}&startDateFrom=${startDate}&startDateTo=${endDate}&sortParam=startDate:asc`;
    url += eventName !== null ? `&eventName=${eventName}` : ``;
    url += rbJobId !== null ? `&rbJobId=${rbJobId}` : ``;
    const config = {
      method: "get",
      url: url,
    };
    setData({ loading: true });

    try {
      const { data: events } = await http(config);
      setData({
        upcomingEvents: events.content,
        listUpcomingEventsParams: {
          pagination: {
            totalPages: events.totalPages,
            pageNumber: events.number,
          },
        },
        loading: false,
      });
      return events;
    } catch (error) {
      setData({ error: true, loading: false });
      console.error(error);
      throw error;
    }
  };

  /**
   * Create the Event.
   *
   * @param {*} param0
   * @returns
   */
  const createEvent = async ({ transcripts, caseId, ...event }) => {
    event.transcripts = Number(transcripts);
    const eventData = {
      caseId,
      event,
    };

    const config = {
      method: "post",
      url: `/hc/events`,
      data: eventData,
    };
    setData({ loading: true });
    try {
      const { data: newEvent } = await http(config);
      setData({ loading: false });
      return newEvent;
    } catch (error) {
      setData({ loading: false, error: true });
      throw error;
    }
  };

  /**
   * Get the Event given the eventId.
   *
   * @param {*} eventId
   * @returns
   */
  const getEvent = async (eventId) => {
    const config = {
      method: "get",
      url: `/hc/events/${eventId}`,
    };
    try {
      setData({ loading: true });
      const { data: eventData } = await http(config);
      setData({ event: eventData });
      return eventData;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Update the Event.
   *
   * @param {*} param0
   * @returns
   */
  const updateEvent = async ({
    eventId,
    caseId,
    eventName,
    details,
    eventTypeId,
    timeZoneId,
    rbJobId,
    witnessId,
    acMeetingUrl,
    startDate,
    notes,
    roomNumber,
    isRecordingEnabled,
    transcripts,
    courtReporterName,
    courtReporterEmail,
    eventCode,
    eventStatus,
    duration,
    isPasscodeEnabled,
    enableGuestAccess,
    exhibits,
    enableMeeting,
    allowJoinBeforeHost,
    notificationOptions,
  }) => {
    const config = {
      method: "put",
      url: `/hc/events/${eventId}`,
      data: {
        event: {
          eventId,
          eventName,
          details,
          eventTypeId,
          timeZoneId,
          rbJobId,
          witnessId,
          acMeetingUrl,
          startDate,
          notes,
          roomNumber,
          isRecordingEnabled,
          transcripts,
          courtReporterName,
          courtReporterEmail,
          eventCode,
          eventStatus,
          duration,
          isPasscodeEnabled,
          enableGuestAccess,
          exhibits,
          enableMeeting,
          allowJoinBeforeHost,
        },
        notificationOptions: notificationOptions.options,
        addedContactEmails: notificationOptions.addedContacts,
        removedContactEmails: notificationOptions.removedContacts,
      },
    };

    setData({ loading: true });

    try {
      const { data: eventData } = await http(config);
      const eventDataIndex = data.events.findIndex(({ eventId }) => eventId === eventData.eventId);
      console.log({ eventDataIndex }, data.events);

      const updatedData = [...data.events];
      updatedData.splice(eventDataIndex, 1, eventData);
      setData({ loading: false, error: false, events: updatedData });
      console.log({ caseId, updatedData });
      return eventData;
    } catch (error) {
      console.log({ error });
      setData({ loading: false, error });
      throw error;
    }
  };

  const deleteEvent = async (eventId) => {
    const config = {
      method: "delete",
      url: `/hc/events/${eventId}`,
    };

    try {
      await http(config);
      const updatedEvents = data.events.filter((event) => event?.eventId !== eventId);
      setData({ loading: false, events: updatedEvents });
      return data;
    } catch (error) {
      console.log({ error });
      setData({ loading: false, error });
      throw error;
    }
  };

  /**
   * Launch the Zoom Meeting's Join URL.
   *
   * @param {*} eventId
   */
  const joinZoomMeeting = async (eventId) => {
    const zoomMeeting = await getMeetingByHcEventId(eventId);
    window.open(zoomMeeting.join_url, "blank");
  };

  /**
   * Launch the HusebyConnect Event.
   *
   * @param {La} meetingUrl
   */
  const launch20Event = async (meetingUrl) => {
    let _meetingUrl = meetingUrl.replace(/\//g, "");

    // Set the session cookie with the latest token info.
    console.log("Setting the session cookie with the latest tokens from localStorage...");
    const authToken = localStorage.getItem(tokenKey);
    const identityToken = localStorage.getItem(identityKey);
    const refreshToken = localStorage.getItem(refreshTokenKey);
    setLoginCookies(authToken, identityToken, refreshToken);
    console.log("Session cookies set.");

    // Launch the HusebyConnect Event.
    console.log(`Launching HusebyConnect event: ${process.env.REACT_APP_MEETING_URL}/rm/${_meetingUrl}`);
    window.open(`${process.env.REACT_APP_MEETING_URL}/rm/${_meetingUrl}`, "blank");
  };

  const listEventParticipants = async (eventId) => {
    console.log("EventService.listEventParticipants()");
    const config = {
      method: "get",
      url: `/hc/events/${eventId}/participants?pageLength=1000`,
    };
    try {
      const { data: participantData } = await http(config);
      setData({ loading: false, participants: participantData.content });
      return participantData.content;
    } catch (error) {
      setData({ loading: false, error: true });
      throw error;
    }
  };

  const emailEventParticipants = async (eventId, options, contacts) => {
    console.log("EventService.emailEventParticipants()", eventId, options, contacts);
    const config = {
      method: "post",
      url: `/hc/events/${eventId}/participants/email`,
      data: {
        notificationOptions: options,
        contactEmails: contacts,
      },
    };
    try {
      await http(config);
    } catch (error) {
      setData({ loading: false, error: true });
      throw error;
    }
  };

  const addEventParticipant = async ({ eventId, contactId, eventParticipantTypeId }) => {
    console.log("EventService.addEventPartcipant()", eventId, contactId, eventParticipantTypeId);
    const config = {
      method: "post",
      url: `/hc/events/${eventId}/participants`,
      data: {
        contactId,
        eventParticipantTypeId,
        grantAccessToRecordings: 1,
      }, // contactId, eventParticipantTypeId, grantAccessToRecordings
    };

    try {
      const { data: participantData } = await http(config);
      console.log({ participantData });
      const newParticipants = [...data?.participants, participantData];
      setData({ loading: false, participants: newParticipants });
      console.log({ data, newParticipants }, data.participants);
      return data;
    } catch (error) {
      setData({ loading: false, error: true });
      throw error;
    }
  };

  const inviteEventParticipant = async (eventId, contact, eventParticipantTypeId) => {
    const config = {
      method: "post",
      url: `/hc/events/${eventId}/participants/invite`,
      data: {
        contact,
        eventParticipantTypeId,
        grantAccessToRecordings: 1,
      }, // contactId, eventParticipantTypeId, grantAccessToRecordings
    };

    try {
      const { data: participantData } = await http(config);
      const newParticipants = [...data?.participants, participantData];
      setData({ loading: false, participants: newParticipants });
      return data;
    } catch (error) {
      setData({ loading: false, error: true });
      throw error;
    }
  };

  const removeEventParticipant = async ({ eventId, contactId }) => {
    console.log("EventService.removeCasePartcipants()");
    const config = {
      method: "delete",
      url: `/hc/events/${eventId}/participants/${contactId}`,
    };

    try {
      await http(config);
      const updatedParticpants = data.participants.filter(
        (participant) => participant?.contact?.contactId !== contactId
      );
      setData({ loading: false, participants: updatedParticpants });
      return data;
    } catch (error) {
      setData({ loading: false, error: true });
      throw error;
    }
  };

  const updateEventParticipant = async (eventId, participant) => {
    console.log("EventService.updateCasePartcipant()");
    const config = {
      method: "put",
      url: `/hc/events/${eventId}/participants`,
      data: participant, // contactId, eventParticipantTypeId, grantAccessToRecordings
    };
  };

  const getMeetingByHcEventId = async (eventId) => {
    console.log("getMeetingByHcEventId", eventId);
    const config = {
      method: "get",
      url: `/zoom/meetings/event-id/${eventId}`,
    };
    try {
      setData({ loading: true });
      console.log("");
      const { data: meetingData } = await http(config);
      setData({ meeting: meetingData });
      return meetingData;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  return (
    <EventContext.Provider
      value={{
        ...data,
        listCaseEvents,
        listContactEvents,
        listUpcomingEvents,
        createEvent,
        getEvent,
        updateEvent,
        deleteEvent,
        joinZoomMeeting,
        launch20Event,
        listEventParticipants,
        addEventParticipant,
        inviteEventParticipant,
        removeEventParticipant,
        updateEventParticipant,
        emailEventParticipants,
        getMeetingByHcEventId,
      }}
    >
      {props.children}
    </EventContext.Provider>
  );
};
const useEventService = () => React.useContext(EventContext);

export { EventContext, EventProvider, useEventService };
