/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { Suspense, lazy, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Redirect, Route, Switch, useLocation, useHistory } from "react-router-dom";
import { isNil } from "lodash";
import Header from "../components/common/Header";
import { useCaseService } from "services/CaseService";
import { useAuthService } from "@cirrux888/huseby-client-auth";
import { useContactService } from "services/ContactService";
import { Box, Divider, LinearProgress } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useDataService } from "services/DataService";
import { SimpleLoader } from "./loader/LoaderIndex";
import ExhibitsManagement from "./exhibits/ExhibitsManagement";
const LoaderIndex = lazy(() => import("../components/loader/LoaderIndex"));
const ScheduleIndex = lazy(() => import("../components/schedule/ScheduleIndex"));
const ScheduleList = lazy(() => import("./admin/schedule/ScheduleList"));
const CaseManagement = lazy(() => import("./cases/Index"));
const AdminIndex = lazy(() => import("./admin/firms/AdminIndex"));
const FirmsIndex = lazy(() => import("./admin/firms/FirmsIndex"));
const ParticipantsIndex = lazy(() => import("./participants/ParticipantsIndex"));
const InternalIndex = lazy(() => import("./admin/internal/InternalIndex"));
const ReportsIndex = lazy(() => import("./admin/reports/ReportsIndex"));
const FirmMembersIndex = lazy(() => import("./admin/firms/FirmMembersIndex"));
const UsersIndex = lazy(() => import("./admin/users/UsersIndex"));
const ResourcesIndex = lazy(() => import("./admin/resources/ResourcesIndex"));
const TasksIndex = lazy(() => import("./admin/tasks/TasksIndex"));

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    height: "100vh",
    width: "100vw",
    maxWidth: "100vw",
    // backgroundColor: theme.palette.background.default,
  },
  loader: {
    position: "fixed",
    left: '50%',
    top: '50%',
    zIndex: '100'
  }
}));

export default function Index() {
  const { auth, getIdentity } = useAuthService();
  const { data, setData } = useDataService();
  const { getContact } = useContactService();
  const { loading: casesLoading } = useCaseService();
  const classes = useStyles();
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [enableAdminMode, setEnableAdminMode] = React.useState();
  const [allowCaseManagementAccess, setAllowCaseManagementAccess] = React.useState(false);
  React.useEffect(() => {
    const initApp = async () => {
      // Initialize application...
      const contactId = JSON.parse(getIdentity()).contactId;
      setIsLoading(true)
      const contact = await getContact(contactId);
      const contactTypeId = contact.contactTypeId;

      if (contactTypeId === 1 || contactTypeId === 2 || contactTypeId === 3) {
        setEnableAdminMode(true);
      } else {
        setEnableAdminMode(false);
      }

      if (contactTypeId !== 7) {
        setAllowCaseManagementAccess(true);
      } else {
        setAllowCaseManagementAccess(false);
      }

      setIsLoading(false)
      setData({ myContact: contact });
    };
    initApp();
  }, []);

  const getRedirect = () => {
    // QW-TODO: Need to find a better way to check for the
    // activation url and also to get the code.  Using useLocations and
    // useParams doesn't seem to work.
    if (location.search.indexOf("view=activate") !== -1) {
      const params = location.search && location.search.replace("?", "").split("&");
      let code = null;
      for (let i = 0; i < params.length; i++) {
        if (params[i].indexOf("code") !== -1) {
          code = params[i].split("=")[1];
        }
      }
      return {
        pathname: `/reset-password`,
        state: { activationCode: code },
      };
    }

    return {
      pathname: `/schedule`,
    };
  };

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      {casesLoading ? (
        <LinearProgress />
      ) : (
        <LinearProgress
          variant="determinate"
          value={0}
          color="secondary"
          style={{ opacity: 0.1 }}
        />
      )}
      <Header />

      {!isLoading && (
        <Suspense fallback={<SimpleLoader />}>
          <Switch>
            {/* <Routes> */}
            <Route path="/loading">
              <LoaderIndex />
            </Route>

            <Route path="/exhibits">
              <ExhibitsManagement />
            </Route>

            <Route path="/schedule">
              <ScheduleIndex />
            </Route>

            {allowCaseManagementAccess && (
              <Route path="/cases/:caseId">
                <CaseManagement />
              </Route>
            )}

            {allowCaseManagementAccess && (
              <Route path="/cases">
                <CaseManagement />
              </Route>
            )}

            {enableAdminMode === true && (
              <Route exact path="/users/:id">
                <UsersIndex />
              </Route>
            )}
            {enableAdminMode === true && (
              <Route exact path="/admin">
                <ScheduleList />
              </Route>
            )}
            {enableAdminMode === true && (
              <Route exact path="/admin/schedule">
                <ScheduleList />
              </Route>
            )}
            {enableAdminMode === true && (
              <Route exact path="/admin/firms">
                <FirmsIndex />
              </Route>
            )}
            {enableAdminMode === true && (
              <Route exact path="/admin/firmMembers">
                <FirmMembersIndex />
              </Route>
            )}
            {enableAdminMode === true && (
              <Route exact path="/admin/participants">
                <UsersIndex contactType="participants" />
              </Route>
            )}
            {enableAdminMode === true && (
              <Route exact path="/admin/internal">
                <UsersIndex contactType="internal" />
              </Route>
            )}
            {enableAdminMode === true && (
              <Route exact path="/admin/resources">
                <ResourcesIndex />
              </Route>
            )}
            {enableAdminMode === true && (
              <Route exact path="/admin/tasks">
                <TasksIndex />
              </Route>
            )}

            {enableAdminMode === true && (
              <Route exact path="/reports">
                <ReportsIndex />
              </Route>
            )}

            {/* <Redirect exact from="/" to={getRedirect()} /> */}
            <Redirect exact path="/" to="/schedule" />
            <Route path="*" component={NotFound} status={404} />
          </Switch>
        </Suspense>)}
    </Box >
  );
}

const NotFound = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ backgroundColor: "#ffffff", height: "100vh" }}
    >
      <h1>404</h1>
      <Divider />
      <h3>We're sorry! This page is not available. Please try a different event url.</h3>
    </Box>
  );
};

const NoMatch = () => {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
};
