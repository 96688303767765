/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import { DataContext } from "../../services/DataService";

const DELAY = 1500;

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "60vh",
    backgroundColor: theme.palette.background.default,
  },
  progress: {
    color: "#71ee33",
  },
}));

const LoaderIndex = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data } = React.useContext(DataContext);

  React.useEffect(() => {
    setTimeout(() => history.push("/schedule"), DELAY);
  }, [data.cases]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className={classes.root}
      >
        <CircularProgress size={50} className={classes.progress} />
        <br />
        <br />
        <br />
        Loading HusebyConnect v2.0...
      </Box>
    </>
  );
};

export const SimpleLoader = () => {
  const classes = useStyles();

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className={classes.root}
      >
        <CircularProgress size={50} className={classes.progress} />
      </Box>
    </>
  );
};

export default LoaderIndex;
