/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { instance as http } from "@cirrux888/huseby-client-auth";
import { AuthContext } from "@cirrux888/huseby-client-auth";
import { isNil } from "lodash";

const DELAY = 800;

const WitnessContext = React.createContext();

let reducer = (data, newData) => {
  return { ...data, ...newData };
};

const initialState = {
  witnesses: [],
  loading: false,
  error: false,
};

const WitnessProvider = (props) => {
  const { auth } = React.useContext(AuthContext);
  const [data, setData] = React.useReducer(reducer, initialState);

  const listWitnesses = async (caseId) => {
    // Handle listing witnesses for a new case.
    if (isNil(caseId)) {
      setData({ witnesses: [] });
      return;
    }

    const config = {
      method: "get",
      url: `/hc/cases/${caseId}/witnesses`,
    };

    setData({ loading: true });
    try {
      const { data: witnesses } = await http(config);
      setData({ witnesses: witnesses, loading: false });
    } catch (error) {
      setData({ witnesses: [], loading: false, error: true });
    }
  };

  const createWitness = async ({ caseId, firstName, lastName, companyName }) => {
    const config = {
      method: "post",
      url: `/hc/cases/${caseId}/witnesses`,
      data: {
        firstName,
        lastName,
        companyName,
      },
    };

    setData({ loading: true });
    try {
      const { data: witness } = await http(config);
      setData({ witnesses: [...(data?.witnesses || []), witness], loading: false });
      return witness;
    } catch (error) {
      setData({ loading: false, error: true });
      throw error;
    }
  };

  const getWitness = async (caseId, witnessId) => {
    console.log("WitnessService.getWitnesses()");
    const config = {
      method: "get",
      url: `/hc/cases/${caseId}/witnesses/${witnessId}`,
    };
  };

  const updateWitness = async (witness) => {
    console.log("WitnessService.updateWitness()", witness.caseId, witness.witnessId, witness);
    const companyName = witness?.companyName ? witness.companyName : "";
    const config = {
      method: "put",
      url: `/hc/cases/${witness.caseId}/witnesses/${witness.witnessId}`,
      data: {
        ...witness,
        companyName,
      },
    };

    setData({ loading: true });
    try {
      const { data: witness } = await http(config);
      setData({ witnesses: [...(data?.witnesses || []), witness], loading: false });
      return witness;
    } catch (error) {
      setData({ loading: false, error: true });
      throw error;
    }
  };

  const createWitnesses = async (caseId, newWitnesses) => {
    return Promise.all(
      newWitnesses.map(async (witness) => {
        try {
          if (witness.witnessId !== -1) {
            return await updateWitness({ caseId, ...witness });
          } else {
            return await createWitness({ caseId, ...witness });
          }
        } catch (error) {
          throw error;
        }
      })
    );
  };

  return (
    <WitnessContext.Provider
      value={{
        ...data,
        listWitnesses,
        createWitness,
        createWitnesses,
        getWitness,
        updateWitness,
      }}
    >
      {props.children}
    </WitnessContext.Provider>
  );
};

const useWitnessService = () => React.useContext(WitnessContext);

export { WitnessContext, WitnessProvider, useWitnessService };
