/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { AuthProvider } from "@cirrux888/huseby-client-auth";
import { SocketIOProvider } from "@cirrux888/huseby-client-exhibit-editor";
import { DataProvider } from "./services/DataService";
import { CaseProvider } from "./services/CaseService";
import { ContactProvider } from "./services/ContactService";
import { EventProvider } from "./services/EventService";
import { WitnessProvider } from "./services/WitnessService";
import { FirmProvider } from "./services/FirmService";
import { UserProvider } from "./services/UserService";
import { RBProvider } from "./services/RBService";
import MomentUtils from "@date-io/moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { BrowserRouter } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ToastContainer } from "react-toastify";
import { LicenseInfo } from "@mui/x-license-pro";

// Set the MUI license for DataGridPro
LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_LICENSE_PRO}`);

console.logtastic = (text) => {
  console.log(
    `%c ${text} `,
    "color: red; line-height: 120px; font-size: 42px; font-weight: 800; text-shadow: 0 2px 0 #FF0000 , 2px 5px 0 #ff7f00 , 4px 10px 0 #ffff00 , 8px 15px 0 #00ff00 , 12px 20px 0 #0000ff , 16px 25px 0 #4b0082, 20px 30px 0 #9400d3;"
  );
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <DataProvider>
          <SocketIOProvider>
            <CaseProvider>
              <EventProvider>
                <ContactProvider>
                  <WitnessProvider>
                    <FirmProvider>
                      <UserProvider>
                        <RBProvider>
                          <LocalizationProvider utils={MomentUtils}>
                            <ThemeProvider theme={theme}>
                              <StyledEngineProvider injectFirst>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                  <MuiThemeProvider theme={theme}>
                                    <App />
                                    <ToastContainer />
                                  </MuiThemeProvider>
                                </LocalizationProvider>
                              </StyledEngineProvider>
                            </ThemeProvider>
                          </LocalizationProvider>
                        </RBProvider>
                      </UserProvider>
                    </FirmProvider>
                  </WitnessProvider>
                </ContactProvider>
              </EventProvider>
            </CaseProvider>
          </SocketIOProvider>
        </DataProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
